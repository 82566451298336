function Test() {
    return (
        <>
            <p>Info Dump</p>
            <p>LOCAL_URL: {process.env.REACT_APP_LOCAL_URL ?? "MISSING"}</p>
            <p>API_URL: {process.env.REACT_APP_API_URL ?? "MISSING"}</p>
            <p>DASHBOARD_URL: {process.env.REACT_APP_DASHBOARD_URL ?? "MISSING"}</p>
            <p>REGISTRATION_URL: {process.env.REACT_APP_REGISTRATION_URL ?? "MISSING"}</p>
            <p>TRAINING_URL: {process.env.REACT_APP_TRAINING_URL ?? "MISSING"}</p>
            <p>ADMIN_URL: {process.env.REACT_APP_ADMIN_URL ?? "MISSING"}</p>
        </>
    )
}

export default Test;
